import * as React from "react"

import Card from "react-bootstrap/Card"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Title from "../components/title"

import styled from "styled-components"

const ImpressumContent = () => {
  return (
    <Container>
      <ImpressumWrapper>
        <Title title="Impressum" />
        <Card.Body>
          <Card.Title>Leopardis Mindelseden</Card.Title>
          <p>Sylwia Tometzki</p>
          <p>89537 Giengen an der Brenz</p>
          <p>Sachsenhauser Str. 25</p>
        </Card.Body>
      </ImpressumWrapper>
    </Container>
  )
}

const ImpressumWrapper = styled(Card)`
  /* display: center;
  align-items: center;
  justify-content: center; */

  margin: 2rem auto 0;
  text-align: center;

  @media (min-width: 720px) {
    width: 60%;
  }

  @media (min-width: 960px) {
    width: 50%;
  }

  .card-body {
    margin-bottom: 1rem;

    p {
      margin: 0;
      padding: 0;
    }
  }

  .card-title {
    margin-top: -1rem;
    margin-bottom: 1rem;
    font-family: "Pacifico", cursive;
  }
`

export default ImpressumContent
