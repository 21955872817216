import * as React from "react"
import Layout from "../components/layout"
import DataPrivacy from "../components/dataprivacy"
import ImpressumContent from "../components/impressumcontent"

import SEO from "../components/seo"

const ImpressumPage = () => {
  return (
    <Layout>
      <SEO title="Impressum | Leopardis" />
      <main style={{ background: "#FFFEFD", margin: "2rem auto" }}>
        <ImpressumContent title="Impressum" />

        <DataPrivacy />
      </main>
    </Layout>
  )
}

export default ImpressumPage
